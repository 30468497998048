import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import FetchApi from "../api/FetchApi";
import { BaseUrl } from "../BaseUrl";
import Avatar from "../components/Avatar";
import doorOpen from "../images/door-open-fill.png";
import brand from "../images/logo.png";
const Cashback = ({ user }) => {
  const [cashback, setCashback] = useState(0);
  useEffect(() => {
    getCash();
    setInterval(getCash, 5000);
  }, []);

  const getCash = async () => {
    // const res = await FetchApi(`getNavbat.php?id=${user.Id}`, "GET");
    // if (res && res.status == 200) {
    //   if (res.data.length > 0) {
    //     setCashback(res.data[0].bonus);
    //   }
    // }
    axios.post(BaseUrl+"get_navbat.php", {
      userId: user.Id,
      jinsi:user.jinsi 
    })
    .then((response) => {
      // console.log(response);
      if (response && response.status == 200) {
        if(response.data == "-1" || response.data == "ERROR"){
          window.localStorage.removeItem("user");
          window.location.reload(); 
        } else{
            setCashback(response.data);
        }
        // if (response.data.length > 0) {
        //   window.localStorage.setItem("user", JSON.stringify(response.data[0]));
        //   window.location.reload();
        // } else {
        //   message.warning("Парол ёки логин хато");
        // }
      } 
    })
    .catch((error) => {
      console.error(error);
    }); 
  };
  function numberFormat(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else return x;
  }

  const leave = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };
  
  const onClickClose = () => {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  };
  const onClickSms = () => {
    // let person = prompt("Please enter your name", "Harry Potter");
    // if (person != null) {
    //   document.getElementById("demo").innerHTML =
    //   "Hello " + person + "! How are you today?";
    // }  
      // var modal = document.getElementById("myModal");
      // modal.style.display = "block";

      // window.location = "/chat";


      Swal.fire({
        title: "Xabar qoldiring...",
        input: "textarea", 
        inputPlaceholder: "Izox qoldiring...",
        inputAttributes: {
          "aria-label": "Izox qoldiring"
        },
        showCancelButton: true,
        confirmButtonColor:"var(--main-color)",
        cancelButtonText:"Ortga",
        confirmButtonText: "Jo'natish",
        showLoaderOnConfirm: true,

        preConfirm: async (login) => {
          // try {
          //   const githubUrl = `
          //     https://api.github.com/users/${login}
          //   `;
          //   const response = await fetch(githubUrl);
          //   if (!response.ok) {
          //     return Swal.showValidationMessage(`
          //       ${JSON.stringify(await response.json())}
          //     `);
          //   }
          //   return response.json();
          // } catch (error) {
          //   Swal.showValidationMessage(`
          //     Request failed: ${error}
          //   `);
          // }  
          axios.post(BaseUrl+"in_izox.php", {
            userId: user.Id,
            izox: login 
          })
          .then((response) => {
            // console.log(response);
            if (response && response.status == 200) {
                  console.log(response.data);
              if(response.data.status == true){
                  Swal.fire({
                    title: "Muvaffaqiyatli!",
                    text: "Xabaringiz muvaffaqiyatli yetkazildi. Tez orada sizga bog'lanamiz.",
                    icon: "success"
                  });
                } else {
                  Swal.fire({
                    title: "Xatolik",
                    text: "Xabar jo'natishda xatolik bo'ldi.\n" + response.data.message,
                    icon: "error"
                  });
                }
            } 
          })
          .catch((error) => {
            console.error(error);
            
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }); 
        },
        allowOutsideClick: () => !Swal.isLoading()
      });

  };

  // window.onclick = function(event) {
  //   var modal = document.getElementById("myModal");
  //   if (event.target == modal) {
  //     modal.style.display = "none";
  //   }
  // }
  return (
    <div className="cashback">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h3 style={{ color: "white", textAlign: "left" }}>ID = {user.reg_nomeri == "0" ? user.Id : user.reg_nomeri}</h3>{" "}
        
        <h3>
          <img
            onClick={leave}
            style={{ cursor: "pointer" }}
            src={doorOpen}
            alt="door open"
          />
        </h3>
      </div>
      <div className="cashback_area">
        <h3 style={{ color: "var(--main-color)", fontWeight: "550" }}>
          {user.fam_ism}
        </h3>
        <h3 style={{ color: "black", textAlign: "center", fontSize:"25px", fontWeight:"800"}}>{user.fio}</h3>{" "}
        <h3 style={{ color: "black", textAlign: "center", fontWeight:"600" }}>{user.pasport}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>{user.telefon1}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>Registratsiya sanasi: {user.reg_date}</h3>
       
        <h2 id="demo" style={{ color: "red", textAlign: "center", margin:"0px" }}>Sizdan oldin navbatda shuncha talaba mavjud:</h2>
        <h1 style={{ color: "var(--main-color)",
                    fontWeight: "bold" , fontSize:"50px"}}>
          {cashback}
        </h1>
          <img style={{width:"120px"}} src={brand}></img>
          <div style={{height:"40px"}}></div>
          <div>
            <button className="mybutton" onClick={onClickSms}>Xabar qoldirish</button>
          </div>
          <div style={{height:"40px"}}></div>
          <h3 style={{ color: "black", textAlign: "center", fontSize:"20px", fontWeight:"800"}}></h3>
        <div className="avatar">
          <Avatar user={user}></Avatar>
        </div>
      </div>
       
    </div>
  );
};

export default Cashback;

import axios from "axios";
import React, { useEffect, useState } from "react";
import FetchApi from "../api/FetchApi";
import { BaseUrl } from "../BaseUrl";
import Avatar from "../components/Avatar";
import doorOpen from "../images/door-open-fill.png";
import brand from "../images/logo.png";
const PageOqimoqda = ({ user }) => {
  const [cashback, setCashback] = useState(0);
  useEffect(() => {
    // getCash();
    // setInterval(getCash, 5000);
  }, []);

  const getCash = async () => {
    // const res = await FetchApi(`getNavbat.php?id=${user.Id}`, "GET");
    // if (res && res.status == 200) {
    //   if (res.data.length > 0) {
    //     setCashback(res.data[0].bonus);
    //   }
    // }
    axios.post(BaseUrl+"get_navbat.php", {
      userId: user.Id,
      jinsi:user.jinsi 
    })
    .then((response) => {
      console.log(response);
      if (response && response.status == 200) {
        if(response.data == "-1" || response.data == "ERROR"){
          window.localStorage.removeItem("user");
          window.location.reload(); 
        } else{
            setCashback(response.data);
        }
        // if (response.data.length > 0) {
        //   window.localStorage.setItem("user", JSON.stringify(response.data[0]));
        //   window.location.reload();
        // } else {
        //   message.warning("Парол ёки логин хато");
        // }
      } 
    })
    .catch((error) => {
      console.error(error);
    }); 
  };
  function numberFormat(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else return x;
  }

  const leave = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };
  return (
    <div className="cashback">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3 style={{ color: "white", textAlign: "left" }}>ID = {user.reg_nomeri == "0" ? user.Id : user.reg_nomeri}</h3>{" "}
        
        <h3>
          <img
            onClick={leave}
            style={{ cursor: "pointer" }}
            src={doorOpen}
            alt="door open"
          />
        </h3>
      </div>
      <div className="cashback_area">
        <h3 style={{ color: "var(--main-color)", fontWeight: "550" }}>
          {user.fam_ism}
        </h3>
        <h3 style={{ color: "black", textAlign: "center", fontSize:"25px", fontWeight:"800"}}>{user.fio}</h3>{" "}
        <h3 style={{ color: "black", textAlign: "center", fontWeight:"600" }}>{user.pasport}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>{user.telefon1}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>Registratsiya sanasi: {user.reg_date}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>Boshlanish sanasi: {user.date_start}</h3>
        <h3 style={{ color: "black", textAlign: "center" }}>Tugash sanasi: {user.date_finish}</h3>
       
        <h2 style={{ color: "red", textAlign: "center", margin:"0px" }}></h2>
        <h1 style={{ color: "var(--main-color)",
                    fontWeight: "bold" , fontSize:"50px"}}>
          {user.holati == "0" ? "Yangi":""}
          {user.holati == "1" ? "O'qimoqda":""}
          {user.holati == "2" ? "Tugatgan":""}
          {user.holati == "3" ? "Kechiktirilgan":""}
          {user.holati == "4" ? "Chetlatilgan":""}
          {user.holati == "5" ? "Bog'lanish uchun":""}
        </h1>
          <img style={{width:"100px"}} src={brand}></img>
        <div className="avatar">
          <Avatar user={user}></Avatar>
        </div>
      </div>
    </div>
  );
};

export default PageOqimoqda;

import { Input, message } from "antd";
import React, { useEffect, useState } from "react";
import brand from "../images/logo.png";
import phone from "../images/lock.svg";
import lock from "../images/lock.svg";
import axios from "axios";

import InputMask from "react-input-mask";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import MyButton from "../components/MyButton";
import { Link } from "react-router-dom";
import Ripples from "react-ripples";
import FetchApi from "../api/FetchApi";
import { BaseUrl } from "../BaseUrl";

const Replace = (x) => {
  if (x == null) x = "";

  let y = x.replace("+", "");
  y = y.replace(")", "");
  y = y.replace("(", "");
  y = y.replace(/ +/g, "");
  return y;
};

const Login = () => {
  const [login, setLogin] = useState({});
  const [loading, setLoading] = useState(false);
  const handleFocus = (e) => {
    if (e.target.parentElement.classList.length == 2) {
      e.target.parentElement.parentElement.classList.add("oranged");
    } else {
      e.target.parentElement.classList.add("oranged");
    }
  };
  const handleBlur = (e) => {
    if (e.target.parentElement.parentElement.classList.length > 1) {
      e.target.parentElement.parentElement.classList.remove("oranged");
    }

    if (e.target.parentElement.classList.length > 1) {
      e.target.parentElement.classList.remove("oranged");
    }
  };
  const handleChange = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  }; 
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      pasport: login.telefon,
    };
    console.log(Replace(login.telefon));
    setLoading(true);
    // const res = await FetchApi("/loginUser.php", "POST", data);
    // if (res && res.status == 200) {
    //   console.log(data);
    //   if (res.data.length > 0) {
    //     window.localStorage.setItem("user", JSON.stringify(res.data[0]));
    //     window.location.reload();
    //   } else {
    //     message.warning("Парол ёки логин хато");
    //   }
    // }
    axios.post(BaseUrl+"loginUser.php", {
        pasport: Replace(login.telefon)
      })
      .then((response) => {
        console.log(response);
        if (response && response.status == 200) {
          
          if (response.data.length > 0) {
            window.localStorage.setItem("user", JSON.stringify(response.data[0]));
            window.location.reload();
          } else {
            message.warning("Парол ёки логин хато");
          }
        }
        // if (response.data.status === "success") {
        //   sessionStorage.setItem("loggedIn", true);
        //   sessionStorage.setItem(
        //     "userData",
        //     JSON.stringify(response.data.data)
        //   );

        //   window.location.href = "/dashboard";
        // } else {
        //   setError(response.data.message);
        // }
      })
      .catch((error) => {
        console.error(error);
      }); 
    setLoading(false);
  };
  return (
    <div>
      <img className="brand_img" src={brand} alt="brand"></img>
      <form onSubmit={handleSubmit}>
        <div style={{ gridGap: "30px", display: "grid" }}>
          <div className="input_container;input">Pasport Seriya</div>
          <div className="input_container">
            <img src={phone}></img>
            <InputMask
              mask="aa 999 99 99"
              maskChar="-"
              onChange={handleChange}
              name="telefon"
              alwaysShowMask={true}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          {/* <div className="input_container">
            <img src={lock}></img>
            <Input.Password
              onBlur={handleBlur}
              onChange={handleChange}
              name="parol"
              onFocus={handleFocus}
              placeholder="Парол"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </div> */}
        </div>

        <div style={{ marginTop: "56px" }}>
          <Ripples onClick={handleSubmit} type="submit">
            <MyButton>
              {loading ? <LoadingOutlined></LoadingOutlined> : "Кириш"}
            </MyButton>
          </Ripples>
          <br />
          {/* <br></br>
          <Link
            style={{ color: "var(--main-color)", textDecoration: "underline" }}
            to="/signup"
          >
            Рўйхатдан ўтиш
          </Link> */}
        </div>
      </form>
    </div>
  );
};

export default Login;
